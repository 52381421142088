import React, { Component, Fragment } from "react";
import {
    Row,
    Button,
    FormGroup,
    Label,
    Input,
    Card,
    CardBody,
    Form,
    FormText
} from "reactstrap";
import { ReactSortable as Sortable } from "react-sortablejs";

import { firestore, storage } from 'firebase';
import CustomSelectInput from "../../../components/common/CustomSelectInput";

import AddNewModifierList from "../../../containers/applications/AddNewModifierList";
import ImageUploader from 'react-images-upload';
import Resizer from 'react-image-file-resizer';
import Select from "react-select";

import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { getDirection } from "../../../helpers/Utils";

class Start extends Component {
    constructor(props) {
        super(props);
        this.onDrop = this.onDrop.bind(this);

        this.state = {
            edit: this.props.edit ? Object.assign({}, this.props.edit) : {},
            allItems: this.props.allItems ? this.props.allItems : [],
            dropdownSplitOpen: false,
            modalOpen: false,
            lastChecked: null,
            expand: {},
            displayOptionsIsOpen: false,
            modification: {
                modifierList: []
            },
            loading: false
        };
    }

    componentWillMount() {

        let item = Object.assign({}, this.props.edit)
        let { allItems } = this.props
        if (item.modifierList) {
            item.modifierList = item.modifierList.map(list => {

                list.items = list.items.map((item, index) => {
                    let itemOject = allItems.filter(value => value.id === item)[0];
                    if (itemOject) {
                        return { label: itemOject.name + ' "' + window.currency + " " + itemOject.price + '"', id: String(index), key: String(index), value: itemOject.name, data: itemOject }
                    }
                    return false
                }).filter(rec => !!rec)
                return list
            })
        }
        let modification = {
            modifierList: item.modifierList ? item.modifierList : [],
            hide: item.hide ? item.hide : false,
            type: item.type ? item.type : 'item',
            advPrice: item.advPrice ? item.advPrice : false,
            price: item.price ? item.price : '0'
        }
        let allItemsCopy = Object.assign([], allItems)
        let modifiersOptions = allItemsCopy.filter(item => item.type === 'modifier')
        this.setState({ modification, modifiersOptions })
    }
    componentDidMount() {
        let { modification } = this.state
        let { allItems } = this.props
        let _this = this
        let reskey = localStorage.getItem('res')
        let modifKeys = []
        modification.modifierList.map(modifier => {
            modifKeys.push(modifier.key)
        })

        firestore().collection('resturant').doc(reskey).collection('modifiersList').onSnapshot(function (snap) {
            let modifierList = []
            snap.forEach(doc => {

                let modifier = doc.data()
                if (modifier.items) {
                    let newOptions = modifier.items.map((item, index) => {
                        let itemOject = allItems.filter(value => value.id === item)[0];
                        if (itemOject) {
                            return { label: itemOject.name + ' "' + window.currency + " " + itemOject.price + '"', id: String(index), key: String(index), value: itemOject.name, data: itemOject }
                        }
                        return false
                    }).filter(rec => !!rec)
                    modifier.items = newOptions
                }
                modifier.value = doc.data().name
                modifier.label = doc.data().name
                modifier.key = doc.id
                if (!modifKeys.includes(doc.id)) {
                    modifierList.push(modifier)
                }
            })


            _this.setState({ modifierList })
        })

    }
    updateItem() {

        let { modification, edit, pictures } = this.state
        let { addToList } = this.props

        let updateItem = Object.assign({}, edit);
        // eslint-disable-next-line 
        Object.keys(modification).map(key => {
            updateItem[key] = modification[key]
        })
        delete modification.startupPictures

        const modifierList = updateItem.modifierList
        let optimizedLkist = modifierList.map(item => {

            let optimizeedItem = item.items.map(innerItem => {
                if (innerItem.data) {
                    return innerItem.data.id
                } else {
                    let reskey = localStorage.getItem('res')
                    let ref = firestore().collection('resturant').doc(reskey).collection('menuItems')
                    let newId = ref.doc().id
                    let newData = innerItem.newData
                    newData.type = "modifier"
                    newData.id = newId
                    ref.doc(newId).set(newData)
                    return newId
                }
            })
            item.items = optimizeedItem
            return item
        })
        updateItem.modifierList = optimizedLkist
        let reskey = localStorage.getItem('res')

        let id = updateItem.id ? updateItem.id : firestore().collection('resturant').doc(reskey).collection('menuItems').doc().id;
        if (pictures) {
            let reskey = localStorage.getItem('res')
            var metadata = {
                cacheControl: 'public,max-age=300',
            };
            let uploadPromises = []
            pictures.forEach(pic => {
                let upload = new Promise((resolve, reject) => {

                    var storageRef = storage().ref().child('resturant').child(reskey).child("menu").child(id).child("img" + pic.name).put(pic.data, metadata);
                    storageRef.on('state_changed', function (snapshot) {
                    }, function (error) {
                        // Handle unsuccessful uploads
                    }, function () {

                        console.log("storageRef.snapshot.ref.fullPath", storageRef.snapshot.ref.fullPath);
                        resolve(`https://img.food2order.io/${storageRef.snapshot.ref.fullPath}?dum=${Date.now()}`)

                        // Handle successful uploads on complete
                        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                        // storageRef.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                        //     resolve(`img.food2order.io/${storageRef.snapshot.ref.fullPath}`)
                        // })
                    })
                })
                uploadPromises.push(upload)
            })
            Promise.all(uploadPromises).then((result) => {
                // let uploadPictures = {}
                // result.map(item => {
                //     uploadPictures[item.name] = item.data
                //     return
                // })

                firestore().collection('resturant').doc(reskey).collection('menuItems').doc(id).set({
                    pictures: result
                }, { merge: true })
            })

        }
        let ref = firestore().collection('resturant').doc(reskey).collection('menuItems').doc(id);
        ref.set(updateItem, { merge: true }).then(() => {
            if (addToList) {
                updateItem.id = id
                updateItem.addNew = true
                addToList(updateItem)
            } else {
                this.props.dismiss()
            }
        }).catch(error => {
            this.setState({ saveDisable: false })
            console.log("error", error)
        })

    }
    onDrop(picture) {
        let { modification, edit } = this.state
        let pictures = []
        let _this = this
        if (picture.length) {
            let lastPic = picture[picture.length - 1]
            Resizer.imageFileResizer(
                lastPic,
                400,
                400,
                'jpeg',
                60,
                0,
                uri => {
                    pictures.push({
                        name: '400',
                        data: uri
                    });
                    Resizer.imageFileResizer(
                        lastPic,
                        1080,
                        1920,
                        'jpeg',
                        100,
                        0,
                        uri600 => {
                            pictures.push({
                                name: '600',
                                data: uri600
                            });
                            _this.setState({ pictures, modification })
                        },
                        'blob'
                    );
                },
                'blob'
            );
            // const reader = new FileReader();
            // reader.readAsDataURL(lastPic);
            // reader.onload = event => {
            //     const img = new Image();
            //     img.src = event.target.result;
            //     img.onload = () => {
            //         const elem = document.createElement('canvas');
            //         let width = 400
            //         elem.width = width;
            //         const scaleFactor = width / img.width;
            //         elem.height = img.height * scaleFactor;
            //         const ctx = elem.getContext('2d');
            //         // img.width and img.height will contain the original dimensions
            //         ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
            //         ctx.canvas.toBlob((blob) => {
            //             const file = new File([blob], picture.name, {
            //                 type: 'image/jpeg',
            //                 lastModified: Date.now()
            //             });
            //             // console.log("filefile", file)

            //             // modification.startupPictures = [event.target.result]
            //             this.setState({
            //                 pictures: [file],
            //                 modification
            //             })
            //         }, 'image/jpeg', 1);

            //     }

            //     reader.onerror = (error) => console.log(error);
            // };

        } else {
            modification.pictures = false
            edit.pictures = []
            this.setState({ modification, edit, pictures: [] })
        }
    }
    handleArrange(e) {
        let { modification } = this.state
        let list = modification.modifierList
        let newList = []

        e.forEach((element, index) => {

            let item = list.filter(item => {
                return Number(item.id) === Number(element)
            })[0]

            if (item) {
                let itemCopy = JSON.parse(JSON.stringify(item))
                itemCopy.id = String(index)
                newList.push(itemCopy)
            }

        })
        modification.modifierList = newList
        this.setState({ modification })
    }

    removeList(item) {
        let { modification, modifierList } = this.state
        let id = item.id

        modifierList.push(item)

        let newArray = modification.modifierList.filter(item => item.id !== id)
        modification.modifierList = newArray.map((item, i) => {
            item.id = String(i)
            return item
        })

        this.setState({ modification, modifierList })
    }
    render() {
        const { edit, modification, modifiersOptions, modifierList } = this.state
        const direction = getDirection();

        console.log(edit.pictures)
        console.log(modification.pictures)
        return (
            <Fragment>

                <Row className="mb-4">
                    <Colxx xxs="12">
                        <Card>
                            <CardBody>
                                <Form>
                                    <FormGroup row>

                                        <Colxx sm={8}>
                                            <Row className='mb-4'>
                                                <Colxx sm={6}>
                                                    <Label for="name">
                                                        <IntlMessages id={"menus.item-name"} />

                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        name="name"
                                                        id="name"
                                                        placeholder={direction.isRtl ? "الاسم" : "Name"}
                                                        defaultValue={edit.name}
                                                        value={modification.name}
                                                        onChange={event => {
                                                            modification.name = event.target.value
                                                            this.setState({ modification })
                                                        }}
                                                    />
                                                </Colxx>
                                                <Colxx sm={6}>
                                                    <Label for="arabic">
                                                        <IntlMessages id={"menus.item-arabic-name"} />
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        name="arabic"
                                                        id="arabic"
                                                        placeholder={direction.isRtl ? "الاسم بالعربية" : "Arabic Name"}
                                                        defaultValue={edit.name_ar}
                                                        value={modification.name_ar}
                                                        onChange={event => {
                                                            modification.name_ar = event.target.value
                                                            this.setState({ modification })

                                                        }}
                                                    />
                                                </Colxx>

                                            </Row>
                                            <Row className='mb-4'>


                                                {
                                                    modification.advPrice ?
                                                        <>
                                                            <Colxx sm={3}>
                                                                <Label for="price" className='mb-0'>
                                                                    <IntlMessages id={"menus.delivery-item-price"} />

                                                                </Label>
                                                                <FormText color="muted">
                                                                    "<IntlMessages id={"menus.include-vat"} />"
                                                                </FormText>
                                                                <Input
                                                                    type="number"
                                                                    name={"priceDelivery"}
                                                                    id={"priceDelivery"}
                                                                    placeholder="20"
                                                                    defaultValue={modification.advPrice && modification.advPrice.delivery}
                                                                    value={modification.advPrice && modification.advPrice.delivery}
                                                                    onChange={event => {
                                                                        modification.advPrice.delivery = event.target.value

                                                                        this.setState({ modification })
                                                                    }}
                                                                />
                                                            </Colxx>
                                                            <Colxx sm={3}>
                                                                <Label for="price" className='mb-0'>
                                                                    <IntlMessages id={"menus.dine-in-item-price"} />

                                                                    {/* // "Dine In" Item Price */}
                                                                </Label>
                                                                <FormText color="muted">
                                                                    "<IntlMessages id={"menus.include-vat"} />"
                                                                </FormText>
                                                                <Input
                                                                    type="number"
                                                                    name="priceDinin"
                                                                    id="priceDinin"
                                                                    placeholder="20"
                                                                    defaultValue={modification.advPrice && modification.advPrice.dineIn}
                                                                    value={modification.advPrice && modification.advPrice.dineIn}
                                                                    onChange={event => {
                                                                        modification.advPrice.dineIn = event.target.value
                                                                        this.setState({ modification })

                                                                    }}
                                                                />
                                                            </Colxx>
                                                            <Colxx sm={3}>
                                                                <Label for="price" className='mb-0'>
                                                                    <IntlMessages id={"menus.pickup-item-price"} />

                                                                    {/* "Pickup" Item Price */}
                                                                </Label>
                                                                <FormText color="muted">
                                                                    "<IntlMessages id={"menus.include-vat"} />"
                                                                </FormText>
                                                                <Input
                                                                    type="number"
                                                                    name="pricePickup"
                                                                    id="pricePickup"
                                                                    placeholder="20"
                                                                    defaultValue={modification.advPrice && modification.advPrice.pickup}
                                                                    value={modification.advPrice && modification.advPrice.pickup}
                                                                    onChange={event => {
                                                                        modification.advPrice.pickup = event.target.value
                                                                        this.setState({ modification })

                                                                    }}
                                                                />
                                                            </Colxx>
                                                        </>
                                                        :
                                                        <Colxx sm={3}>
                                                            <Label for="price" className='mb-0'>
                                                                <IntlMessages id={"menus.item-price"} />

                                                            </Label>
                                                            <FormText color="muted">
                                                                "<IntlMessages id={"menus.include-vat"} />"
                                                            </FormText>
                                                            <Input
                                                                type="number"
                                                                name={'price'}
                                                                id={'price'}
                                                                placeholder="20"
                                                                defaultValue={modification.price}
                                                                value={modification.price}
                                                                onChange={event => {
                                                                    modification.price = event.target.value
                                                                    this.setState({ modification })
                                                                }}
                                                            />
                                                        </Colxx>

                                                }
                                                <div className="d-flex align-items-sm-end mt-2">
                                                    <Button color='primary' size='xs' onClick={() => {
                                                        if (modification.advPrice) {
                                                            modification.advPrice = false
                                                        } else {
                                                            modification.advPrice = {};
                                                        }
                                                        this.setState({ modification })
                                                    }}> {modification.advPrice ? <IntlMessages id={"menus.hide-adv-pricing"} />
                                                        : <IntlMessages id={"menus.show-adv-pricing"} />}</Button>
                                                </div>

                                            </Row>
                                            <FormGroup className='mb-4'>
                                                <Label for="Details">
                                                    <IntlMessages id={"menus.item-description"} />
                                                </Label>
                                                <Input
                                                    type="textarea"
                                                    name="Details"
                                                    id="Details"
                                                    placeholder={direction.isRtl ? "التفاصيل" : "Details"}
                                                    defaultValue={edit.detail}
                                                    value={modification.detail}
                                                    onChange={event => {
                                                        modification.detail = event.target.value
                                                        this.setState({ modification })

                                                    }}
                                                />
                                            </FormGroup>
                                            <FormGroup className='mb-4'>
                                                <Label for="Arabic Details">
                                                    <IntlMessages id={"menus.item-arabic-description"} />
                                                </Label>

                                                <Input
                                                    type="textarea"
                                                    name="ArabicDetails"
                                                    id="ArabicDetails"
                                                    placeholder={direction.isRtl ? "تفاصيل بالعربية" : "Arabic Details"}
                                                    defaultValue={edit.detail_ar}
                                                    value={modification.detail_ar}
                                                    onChange={event => {
                                                        modification.detail_ar = event.target.value
                                                        this.setState({ modification })

                                                    }}
                                                />
                                            </FormGroup>

                                        </Colxx>

                                        {
                                            modification.type === 'item' && <Colxx sm={4}>
                                                <Row className='justify-content-center mb-4'>

                                                    {edit && edit.pictures && edit.pictures.length ?
                                                        <ImageUploader
                                                            className='w-80'
                                                            withIcon={true}
                                                            label={'Max file size: 15mb, accepted: jpg|gif|png'}
                                                            buttonText='Choose images'
                                                            onChange={this.onDrop}
                                                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                                            maxFileSize={15728640}
                                                            withPreview={true}
                                                            defaultImages={[edit.pictures[0]]}
                                                        /> : <ImageUploader
                                                            className='w-80'
                                                            withIcon={true}
                                                            label={'Max file size: 15mb, accepted: jpg|gif|png'}
                                                            buttonText='Choose images'
                                                            onChange={this.onDrop}
                                                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                                            maxFileSize={15728640}
                                                            withPreview={true}
                                                        />}
                                                </Row>

                                            </Colxx>
                                        }

                                    </FormGroup>
                                    {
                                        modification.type === 'item' && <div>

                                            <Separator className='mb-4' />

                                            <h4 className='mb-4'><IntlMessages id={"menus.customizations"} />:</h4>
                                            <Select
                                                components={{ Input: CustomSelectInput }}
                                                className="react-select text-capitalize mb-4"
                                                classNamePrefix="react-select"
                                                name="form-field-name"
                                                options={modifierList && modifierList.map(rec => {
                                                    return { ...rec, value: rec.name, label: rec.displayName ? (rec.name + " ( " + rec.displayName + " ) ") : rec.name }
                                                })}
                                                value={this.state.selectedType}
                                                onChange={val => {
                                                    // val.items = modifiersOptions.filter(option => {
                                                    //     if (val.items.includes(option.id)) {
                                                    //         option.value = option.name
                                                    //         option.label = option.name
                                                    //         option.key = option.id
                                                    //         option.data = option
                                                    //         return option
                                                    //     }
                                                    // })
                                                    val.id = String(modification.modifierList.length)
                                                    modification.modifierList.push(val)

                                                    let newlist = modifierList.filter(modifier => {
                                                        return (modifier.key !== val.key)
                                                    })
                                                    this.setState({ modification, modifierList: newlist })


                                                }}
                                            />
                                            {
                                                modification && modification.modifierList && modification.modifierList.length ?

                                                    <Sortable
                                                        list={modification.modifierList}
                                                        setList={(newState) => {
                                                            modification.modifierList = newState
                                                            this.setState({ modification })
                                                        }}
                                                        setl
                                                        tag="ul"
                                                        className="list-unstyled"
                                                        options={{
                                                            handle: ".handle",
                                                            animation: 150
                                                        }}
                                                    // onChange={(e) => this.handleArrange(e)}
                                                    >
                                                        {
                                                            modification.modifierList.map(item => {
                                                                return (
                                                                    <li key={item.id} id={item.id} data-id={item.id} className='handle'>
                                                                        <Card >
                                                                            <Row className='p-3 '>
                                                                                <div className='w-90 d-flex'>

                                                                                    <span className=" badgeing badge-pill w-5 d-flex align-items-center" onClick={() => {// eslint-disable-next-line
                                                                                        this.state.expand["test"] = !this.state.expand["test"]
                                                                                    }}>
                                                                                        <i className='simple-icon-cursor-move' />
                                                                                    </span>
                                                                                    <div className='w-20'>{item.name}</div>
                                                                                    <div className='w-30'>{item.items ? item.items.length : ''}</div>
                                                                                </div>

                                                                                <div className="badgeing badge-pill handle w-5 d-flex align-items-center btn" onClick={() => { this.setState({ editList: item, addModifierList: true }) }}>
                                                                                    <i className='simple-icon-pencil' />
                                                                                </div>
                                                                                <div className="badgeing badge-pill handle w-5 d-flex align-items-center btn" onClick={() => { this.removeList(item) }}>
                                                                                    <i className='simple-icon-trash' />
                                                                                </div>

                                                                            </Row>
                                                                        </Card>
                                                                    </li>

                                                                )
                                                            })
                                                        }

                                                    </Sortable>

                                                    : <p><IntlMessages id={"menus.no-modifiers-added"} /></p>

                                            }
                                        </div>
                                    }


                                    <FormGroup >
                                        <Button onClick={() => this.setState({ addModifierList: true })}><IntlMessages id={"menus.add-customization"} /></Button>
                                        <div className="mb-4  float-right">
                                            <Button color='danger' className='m-1' outline onClick={() => this.props.dismiss()}><IntlMessages id={"menus.cancel"} /></Button>
                                            <Button disabled={this.state.saveDisable} color="primary" className='m-1' onClick={() => this.setState({ saveDisable: true }, () => this.updateItem())}>
                                                <IntlMessages id={"menus.save"} />
                                            </Button>
                                        </div>
                                    </FormGroup>


                                </Form>
                            </CardBody>
                        </Card>
                    </Colxx>
                </Row>
                {
                    this.state.addModifierList && <AddNewModifierList modalOpen={this.state.addModifierList} modifiersOptions={modifiersOptions} editList={this.state.editList} toggleModal={() => this.setState({ addModifierList: false, editList: false })} UpdateList={(item) => { modification.modifierList[item.id] = item }} addNewList={(e) => { e.id = String(modification.modifierList.length); modification.modifierList.push(e) }}></AddNewModifierList>
                }
            </Fragment>
        )

    }
}
export default Start
